<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title> 商品列表 </template>
      <template #input>
        <a-input v-model="name" placeholder="请输入关键字" />
        <a-button type="primary" class="btn" @click="search()">搜索</a-button>
        <a-button class="all_boder_btn" @click="categoryFilling()"
          >新建商品</a-button
        >
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <a-tabs default-active-key="1" @change="callback">
        <a-tab-pane :key="null" tab="全部商品"></a-tab-pane>
        <a-tab-pane
          :key="item.id"
          v-for="item in classData"
          :tab="item.name"
        ></a-tab-pane>
      </a-tabs>

      <a-table
        class="table-template"
        :rowKey="(item) => item.id"
        :columns="columns"
        :data-source="tableData"
        :loading="loading"
        @change="onPage"
        :pagination="{
          total: total,
          current: pageNum,
          defaultPageSize: pageSize,
          showSizeChanger: true,
          showTotal: function (total, range) {
            return `共${total}条`;
          },
        }"
      >
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center">
            {{ (pageNum - 1) * 10 + i + 1 }}
          </div>
        </template>

        <template slot="sort" slot-scope="item,row">
          <a-input-number id="inputNumber" v-model="item" :min="1" @change="onSortChange(item,row)" />
        </template>

        <template slot="operation" slot-scope="text, row">
          <div class="btn_router_link">
            <a
              v-if="text.publishFlagText != '已上架'"
              @click="changeState(text, 1)"
              >上架</a
            >
            <a v-else @click="changeState(text, 0)">下架</a>
            <span>|</span>
            <a @click="selEdit(row.id)">编辑</a>
            <span>|</span>
            <a @click="deleteitem(text)">删除</a>
          </div>
        </template>
      </a-table>
    </div>

    <!-- 商品弹窗 -->
    <a-modal
      v-model="category"
      width="688px"
      title="选择商品"
      :centered="true"
      :closable="false"
    >
      <div class="search-box">
        <a-select
          class="search-input"
          placeholder="请选择类型"
          v-model="categorytype"
          allowClear
        >
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option
            v-for="item in cgoodList"
            :key="item.id"
            :value="item.id"
          >
            {{ item.classify }}
          </a-select-option>
        </a-select>
        <a-input
          class="search-input"
          placeholder="请输入名称"
          v-model="categoryname"
          allowClear
        />
        <a-button class="search-btn" type="primary" @click="oncSearch()"
          >搜索</a-button
        >
      </div>

      <div class="table-box">
        <a-table
          class="table-template"
          :row-selection="rowSelection"
          :rowKey="(item) => item.productId"
          :columns="categorycolumns"
          :data-source="categoryData"
          :pagination="{
            total: ctotal,
            current: cpageNumber,
            pageSize: cpageSize,
            showTotal: (res) => `共${ctotal}条`,
          }"
          bordered
          @change="oncPage"
        >
          <template slot="index" slot-scope="item, row, index">
            {{ (cpageNumber - 1) * cpageSize + index + 1 }}
          </template>

          <template slot="type" slot-scope="item">
            <!-- 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.其他 -->
            <span v-if="item == 1">课程</span>
            <span v-else-if="item == 2">图书</span>
            <span v-else-if="item == 3">教具</span>
            <span v-else-if="item == 4">模拟考试</span>
            <span v-else-if="item == 5">电子照片</span>
            <span v-else-if="item == 6">延期</span>
            <span v-else-if="item == 7">补考</span>
            <span v-else-if="item == 8">直播</span>
            <span v-else-if="item == 9">其他</span>
            <span v-else>-</span>
          </template>
        </a-table>
      </div>

      <template slot="footer">
        <a-button type="primary" @click="categoryOk">确认</a-button>
        <a-button @click="categoryCancel">取消</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "商品名称",
    align: "center",
    dataIndex: "productName",
  },
  // {
  //   title: "商品分类",
  //   align: "center",
  //   dataIndex: "categoryName",
  // },
  {
    title: "上架状态",
    align: "center",
    dataIndex: "publishFlagText",
  },
  {
    title: "个人端单价",
    align: "center",
    dataIndex: "couponPrice",
  },
  {
    title: "机构端单价",
    align: "center",
    dataIndex: "orgPrice",
  },
  {
    title: "排序",
    align: "center",
    dataIndex: "dictionary_sort",
    scopedSlots: { customRender: "sort" },
  },

  {
    title: "操作",
    align: "center",
    width: "200px",
    scopedSlots: { customRender: "operation" },
  },
];
const categorycolumns = [
  {
    title: "序号",
    align: "center",
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  {
    title: "商品类型",
    align: "center",
    dataIndex: "type",
    scopedSlots: {
      customRender: "type",
    },
  },
  {
    title: "商品名称",
    align: "center",
    dataIndex: "name",
  },
];
import HeaderBox from "@/components/HeaderBox.vue";

export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data() {
    return {
      // 替换字段
      replaceFields: {
        title: "name",
        value: "id",
        key: "id",
      },
      // 商品弹窗
      category: false,
      categoryname: undefined,
      categorytype: undefined,
      categorycolumns, // 选择商品
      categoryData: [],
      ctotal: 0,
      cpageNumber: 1, // 页码
      cpageSize: 5, // 页数
      // selectedRowKeys: [],
      // 商品类别
      cgoodList: [
        { id: 0, classify: "全部" },
        { id: 1, classify: "课程" },
        { id: 2, classify: "图书" },
        { id: 3, classify: "教具" },
      ],

      columns, // 表头
      tableData: [], // 列表数据
      loading: false, // 列表加载
      total: 0, // 总数量
      pageNum: 1, //页码
      pageSize: 10, // 页数
      // 关键字
      name: "",
      // 全部分类
      classData: [],
      // 分类id
      code: null,
    };
  },
  // 事件处理器
  methods: {
    // 分页
    onPage(e) {
      this.pageNum = e.current;
      this.pageSize = e.pageSize;
      this.getdataList();
    },
    // tab切换
    callback(e) {
      this.code = e;
      this.pageNum = 1;
      this.pageSize = 10;
      this.getdataList();
    },
    // 终端展示权重
    listSort(e, item) {
      console.log(e, item);
    },

    //新建
    categoryOk() {
      if(!this.productId){
        this.$message.warning("请选择商品");
        return
      }
      this.category = false;
      this.$router.push({
        path: "/admin/Mechanism/NewProduct",
        query: { id: this.productId, operation: 1 },
      });
    },
    // 编辑
    selEdit(e) {
      this.$router.push({
        path: "/admin/Mechanism/NewProduct",
        query: { id: e, operation: 2 },
      });
    },
    // 搜索
    search() {
      this.pageNum = 1;
      // this.pageSize = 10;
      this.getdataList();
    },
    // 获取数据
    getdataList() {
      this.loading = true;
      this.$ajax({
        url: "/hxclass-management/organiz-product/list",
        method: "get",
        params: {
          category: this.code, //this.code
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          productName: this.name,
        },
      }).then((res) => {
        this.loading = false;
        let arr = res.data.records;
        for (let index = 0; index < arr.length; index++) {
          if (arr.length - 1 != 0) {
            if (index == 0) {
              arr[index].last = 1;
            } else {
              arr[index].last = 0;
            }
            if (index == arr.length - 1) {
              arr[index].fast = 1;
            } else {
              arr[index].fast = 0;
            }
          }
        }
        this.tableData = arr;
        this.total = res.data.total;
      });
    },
    // 获取分类数据
    getclassData() {
      this.$ajax({
        url: "/hxclass-management/organiz-product-categroy/tree",
        method: "get",
        params: {
          type: 1,
          pid: 0,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.classData = res.data;
        } else {
        }
      });
    },
    // 移动
    moveData(e, r) {
      if (this.loading) {
        return false;
      } else {
        this.loading = true;
      }
      this.$ajax({
        url:
          "/hxclass-management/organiz-product/diction-sort?id=" +
          r +
          "&type=" +
          e,
        method: "put",
        // params: {
        //   id: r,
        //   type: e,
        // },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.getdataList();
          setTimeout(() => {
            this.loading = false;
          }, 2000);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 改变商品状态
    changeState(e, r) {
      this.$ajax({
        url:
          "/hxclass-management/organiz-product/pushlish?id=" +
          e.id +
          "&publishFlag=" +
          r,
        method: "put",
        // params: {
        //   productId: e.id,
        //   publishFlag: r,
        // },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success("操作成功");
          this.getdataList();
        } else {
          this.$message.error(res.message);
        }
      });
    },
    // 删除商品
    deleteitem(e) {
      this.$confirm({
        title: "确定删除该商品吗?",
        centered: true,
        okText: "确认",
        onOk: () => {
          this.$ajax({
            url: "/hxclass-management/organiz-product/del?id=" + e.id,
            method: "put",
            params: {
              // productId: e.productId,
              // ground: r,
            },
          }).then((res) => {
            if (res.code == 200 && res.success) {
              this.$message.success("操作成功");
              this.getdataList();
            } else {
              this.$message.error(res.message);
            }
          });
        },
      });
    },
    getNum() {
      this.$ajax({
        url: "hxclass-management/course/open-time/select/bycode",
        params: {
          productId: this.productId,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.numList = res.data;
        }
      });
    },
    // 商品弹窗
    oncPage(e) {
      this.cpageNumber = e.current;
      this.cpageSize = e.pageSize;
      this.categoryFilling();
    },
    oncSearch() {
      this.categoryFilling();
    },

    categoryCancel(e) {
      this.category = false;
    },
    categoryFilling() {
      this.category = true;
      this.$ajax({
        url: "/hxclass-management/organiz-product/prodict-archives/list",
        params: {
          name: this.categoryname,
          categoryId: this.categorytype == 0 ? null : this.categorytype,
          pageNum: this.cpageNumber,
          pageSize: this.cpageSize,
        },
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.ctotal = res.data.total;
          this.categoryData = res.data.records;
        } else {
          this.$message.error(res.message);
        }
      });
    },
    select(e, v) {
      if (v.length > 0) {
        this.productId = v[0].productId;
        this.productType = v[0].productType;
      }
      this.tradeName = v[0].productName;
    },
    modelCancel() {
      this.isExportShow = false;
      this.$refs.export.checkedList();
    },
    // 修改位置
    onSortChange(e,item) {
      console.log(e)
      console.log(item.id)
      item.sort = e;
      if(this.timer) {
        clearTimeout(this.timer)
      }
      let _this = this;
      this.timer = setTimeout(()=>{
        this.timer = null;
        _this
          .$ajax({
            url: "/hxclass-management/organiz-product/updateDictionarySort",
            method: "put",
            params: {
              id: item.id,
              dictionarySort: e
            }
          })
          .then((res) => {
            if (res.code == 200 && res.success) {
              _this.$message.success("操作成功");
              _this.getdataList();
            }
          });
        // console.log('zoule')
      },600)
    },
  },
  // 生命周期-实例创建完成后调用
  created() {},
  // 生命周期-实例挂载后调用
  mounted() {
    this.getdataList();
    this.getclassData();
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {
    rowSelection() {
      return {
        type: "radio",
        onChange: this.select,
      };
    },
  },
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
.btn {
  margin-right: 24px;
}
.table-box {
  /deep/ .ant-tabs-bar {
    border-bottom: none;
  }
  /deep/ .ant-tabs-bar {
    margin: 0;
  }
}
.all_content_box {
  .line-item {
    margin-bottom: 20px;
    display: flex;
    .all_left_name {
      width: 90px;
      text-align: right;
      margin-right: 20px;
    }
  }
  .right {
    flex: 1;
    /deep/.ant-input {
      height: 40px;
    }
  }
  textarea.ant-input {
    flex: 1;
  }
}
.search-box {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  .search-input {
    width: 36%;
  }
}
</style>
